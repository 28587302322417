<template>
<div class="login-main">
 <nav-bar></nav-bar>
  <section class="order-details-start">
    <div class="review-container review-main text-capitalize position-relative">
      <div class="row">
        <div class="text-center col-md-12 p-0 my-2 odr-loadr" v-if="loading">
          <b-spinner class="align-middle"></b-spinner>
        </div>
        <div class="col-md-12 p-0" v-for="(orders,index) in ordersDetail" :key="index">
          <div class="resturant-details plr15 order-id">
            <h4 class="text-left">{{ $t('Order') }} #{{orders.order_id}}</h4>
          </div>
          <div class="orders-inner-main plr15 mt-2">
            <div class="orders-lst">
              <div class="order-item d-flex align-items-start pb-4">
                <img :src="orders.restaurant_image" />
                <div class="order-data ml-3 d-flex align-items-start justify-content-between w-100">
                  <div class="orderid-main">
                    <h6 class="mb-0">{{orders.restaurant_name}}</h6>
                    <p class="mt-1">{{orders.cuisine_name.join(', ')}}</p>
                    <a class="mt-2 d-inline-block" :href="'tel:'+orders.support_contact_number">
                      <i class="fas fa-phone-alt"></i> {{ $t('Call') }}
                    </a>
                  </div>
                </div>
              </div>
              <div class="inner-data">
                <h6 class="mb-1 mt-3">{{ $t('Your Order') }}</h6>
                <div class="order-history-main" v-for="(restaurant,index) in orders.restaurant_item_details" :key="index">
                  <div class="order-histry d-flex justify-content-between align-items-start">
                    <div class="histry-name item-heading delivery-link">
                      <div class="veg" v-if="restaurant.item_type == 0">
                        <span></span>
                      </div>
                      <div class="non-veg" v-else-if="restaurant.item_type == 1">
                        <span></span>
                      </div>
                      <div v-else-if="restaurant.item_type == 2">
                        <span></span>
                      </div>
                      <p class="mb-0">{{restaurant.restaurant_menu_item_name}}</p>
                      <span v-if="restaurant.price_type != 'kg'">{{restaurant.quantity}} {{ $t('item') }}</span>
                      <small v-else class="text-muted">{{ restaurant.item_weight +' '+ $t('KG')}}</small>
                      <p class="mb-0" v-if="restaurant.item_note != ''">{{truncate(restaurant.item_note,20, '...')}}</p>
                    </div>

                    <div class="d-flex">
                      <div class="histry-price strikeout"  v-if="_.has(restaurant,'old_data.amount') && restaurant.old_data.amount != restaurant.amount">
                        <span v-html="$auth.setting.currency"></span>
                        <span>{{parseFloat(restaurant.old_data.amount || 0).toFixed(2)}}&nbsp;</span>
                      </div>
                      <div class="histry-price">
                        <span v-html="$auth.setting.currency"></span>
                        <span>{{parseFloat(restaurant.amount).toFixed(2)}}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="billing-data border-0 mt-2">
                  <h6 class="mb-2">{{ $t('Billing Details') }}</h6>
                  <ul>
                    <li class="d-flex justify-content-between">
                      {{ $t('SubTotal') }}
                      <div class="d-flex">
                        <div class="mr-3 strikeout" v-if="_.has(orders,'old_data.sub_total') && orders.old_data.sub_total != orders.sub_total">
                          <span v-html="$auth.setting.currency"></span><span>{{parseFloat(orders.old_data.sub_total).toFixed(2)}}</span>
                        </div>
                        <div>
                          <span v-html="$auth.setting.currency"></span><span>{{parseFloat(orders.sub_total).toFixed(2)}}</span>
                        </div>
                      </div>
                    </li>
                    <li class="d-flex justify-content-between">
                      {{ $t('Delivery Charges') }}
                      <div>
                        <span v-html="$auth.setting.currency"></span> <span>{{parseFloat(orders.delivery_fee).toFixed(2)}}</span>
                      </div>
                    </li>
                    <li class="d-flex justify-content-between">
                      {{ $t('GST') }}
                      <div class="d-flex">
                        <div class="mr-3 strikeout" v-if="_.has(orders,'old_data.service_fee') && orders.old_data.service_fee != orders.service_fee">
                          <span v-html="$auth.setting.currency"></span><span>{{parseFloat(orders.old_data.service_fee).toFixed(2)}}</span>
                        </div>
                        <div>
                          <span v-html="$auth.setting.currency"></span><span>{{parseFloat(orders.service_fee).toFixed(2)}}</span>
                        </div>
                      </div>
                    </li>
                    <li class="d-flex justify-content-between discount-price" v-if="orders.discount_price">
                      {{ $t('Discount') }}
                      <div class="d-flex">
                        <div class="mr-3 strikeout" v-if="_.has(orders,'old_data.discount_price') && orders.old_data.discount_price != orders.discount_price">
                          <span v-html="$auth.setting.currency"></span><span>{{parseFloat(orders.old_data.discount_price).toFixed(2)}}</span>
                        </div>
                        <div>
                          <span v-html="$auth.setting.currency"></span><span>{{parseFloat(orders.discount_price).toFixed(2)}}</span>
                        </div>
                      </div>
                    </li>
                    <li class="d-flex justify-content-between bold">
                      {{ $t('Total Paid') }}
                      <div class="d-flex">
                        <div class="mr-3 strikeout" v-if="_.has(orders,'old_data.total_amount') && orders.old_data.total_amount != orders.total_amount">
                          <span v-html="$auth.setting.currency"></span><span>{{parseFloat(orders.old_data.total_amount).toFixed(2)}}</span>
                        </div>
                        <div>
                          <span v-html="$auth.setting.currency"></span><span>{{parseFloat(orders.total_amount).toFixed(2)}}</span>
                        </div>
                      </div>
                    </li>
                    <li class="d-flex justify-content-between walltline" v-if="orders.wallet_amount > 0">
                      {{ $t('Wallet') }}
                      <div class="walltline">
                        - <span v-html="$auth.setting.currency"></span><span>{{parseFloat(orders.wallet_amount).toFixed(2)}}</span>
                      </div>
                    </li>
                    <li class="d-flex justify-content-between text-success" v-if="_.has(orders,'old_data.refund_amount') && orders.old_data.refund_amount > 0">
                      {{ $t('Refund') }}
                      <div>
                        <span class="text-success" v-html="$auth.setting.currency"></span><span class="text-success">{{parseFloat(orders.old_data.refund_amount).toFixed(2)}}</span>
                      </div>
                    </li>
                    <li class="d-flex justify-content-between text-danger" v-if="_.has(orders,'old_data.charge_amount') && orders.old_data.charge_amount > 0">
                      {{ $t('Charge') }}
                      <div>
                        <span class="text-danger" v-html="$auth.setting.currency"></span><span class="text-danger">{{parseFloat(orders.old_data.charge_amount).toFixed(2)}}</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="billing-data border-0 mt-2">
                  <h6 class="mb-2">{{ $t('Order Details') }}</h6>
                  <ul>
                    <li class="d-flex justify-content-between">
                      {{ $t('Order Number') }}
                      <span>#{{orders.order_id}}</span>
                    </li>
                    <li class="d-flex justify-content-between">
                      {{ $t('Placed Date') }}
                      <span>{{moment(orders.order_date).format('DD/MM/Y hh:mm A')}}</span>
                    </li>
                    <li class="d-flex justify-content-between">
                      {{ $t('Order Type') }}
                      <span>{{$t(orders.delivery_pickup_types)}}</span>
                    </li>
                    <li class="d-flex justify-content-between">
                      {{ $t('Payment Mode') }}
                      <span>
                        {{$t(orders.payment_method)}}
                        {{orders.payment_type != 'wallet'?' ('+$t(orders.payment_type)+')':''}}
                      </span>
                    </li>
                    <li class="d-flex justify-content-between delivery-locality">
                      {{ $t('Delivery Address') }}
                      <span>{{orders.delivery_address}}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div id="past" v-if="orders.cancelled_desc" class="tab-pane tab-box cncel-ordr">
            <h6>{{ $t('Cancelled Order') }}</h6>
            <p>{{orders.cancelled_desc}}</p></div>
        </div>
        
        <div class="order-offers" v-if="ordersDetail.length == 0 && !loading">
          <p class="text-center">{{ $t('No Order Found') }}</p>
        </div>
      </div>
    </div>
  </section>
  <Footer></Footer>
</div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import { mapActions, mapGetters, mapState } from 'vuex';
import moment from "moment";
import Footer from '../components/Footer.vue';
import _ from 'lodash';
import { truncate } from "../helper/helper";
export default {
    components: {
        NavBar,
        Footer
    },
    data(){
        return{
            ordersDetail: [],
            moment: moment,
            loading: true,
        }
    },
    computed:{
        ...mapGetters("order",["orderDetail"]),
        _(){
          return _;
        }
    },
    mounted(){
      if(this.$auth.user){
        this.orderDetails();
      }else{
        this.$router.push({ name: "sign-in" });
      }
    },
    methods: {
      ...mapActions("order",["viewOrderDetail"]),
      truncate,
      orderDetails(){
        this.loading = true;
        if(this.isBase64(this.$route.params.order_id)) {
          this.viewOrderDetail({
              vendor_id: this.$auth.getVendorId(),
              order_id: this.base64_decode(this.$route.params.order_id),
              is_langauge : this.$store.state.lang.locale
          }).then(data => {
            if (data.code == 200) {
              this.loading = false;
              this.ordersDetail = data.Result;
            }
            if (data.code == 101) {
              this.loading = false;
              this.ordersDetail = [];
            }
          })
        }else {
          this.loading = false;
          this.ordersDetail = [];
        }
      },

    isBase64(str) {
      if (str ==='' || str.trim() ===''){ return false; }
      try {
        return btoa(atob(str)) == str;
        } catch (err) {
          return false;
        }
      }
    }
};
</script>